@import "styles/constants.scss";

.LayoutHome {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 55%;
  display: flex;
  flex-direction: column;
}

.TouchScreen {
  color: $primary;
  font-size: 84px;
  line-height: 115px;
  font-weight: bold;
  width: 1000px;
  max-width: 700px;
}

.TaglineInfo {
  height: 53px;
  margin-bottom: 75px;
}

.TaglineText {
  color: $primary;
  font-size: $tagline-font-size;
  font-weight: normal;
}
.ConnectedAs {
  color: $primary;
  font-size: $large-font-size;
  font-weight: bold;
  border-bottom: 2px solid;
  height: 36px;
}

.PharmaAccount {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 15px;
  top: 20px;
  left: 50px;
  z-index: 1;
}
.HomepageImage {
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 0;
}

.Contact {
  font-size: $large-font-size;
  margin-top: 50px;
  text-align: center;
  span {
    font-size: $medium-font-size;
    b {
      color: $red;
    }
  }
}

.AccessibilityLink {
  position: absolute;
  left: 1550px;
  right: 0;
  bottom: 30px;
  color: $primary;
  font-size: $default-font-size;
  color: white;
}

.AccessibilityLink a, .AccessibilityLink a:hover, .AccessibilityLink:active, .AccessibilityLink:visited  {
  color: white;
}

.Versioning {
  position: absolute;
  left: 1800px;
  right: 0;
  bottom: 30px;
  color: $primary;
  font-size: $default-font-size;
  color: white;
}

.BannerMessage {
  background-color: #9b4f86 !important;
  font-size: 24px !important;
  color: white;
  width: 520px !important;
  line-height: 32px !important;
}


.click_here_logo {
  background-image: url("/assets/icons/home_hand.gif");
  background-repeat: no-repeat;
  background-size: contain;
  height: 300px;
  width: 200px;
  margin-top: 60px;
}