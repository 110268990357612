@import "styles/constants.scss";
.Button {
  text-transform: uppercase;
  font-size: $large-font-size;
  padding: 8px;
}

// .Button.Primary {
//   background: $primary;
// }
// .Button.Accent  {
//   background: $accent;
// }
// .Button.Error {
//   background: $error;
// }
.Xsmall {
  font-size: $medium-font-size !important;
  height: 70px !important;
}