@import "styles/constants.scss";
.Modal {
}
.CGUContent {
  overflow-y: scroll;
  height: 55vh;
}

.iconTitle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cancelConsultationContent {
  font-size: $xxlarge-font-size;
  color: $primary;
  line-height: 70px;
  font-weight: bold;
  text-align: center;
  margin-top: 50px;
}

.title__block{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title__icon{
width : 6rem;
margin-bottom: 2rem;
}

.title__icon img{
  width : 100%;
}

.title__text{
font-weight: bold;
font-size: 65px;
text-align: center;
color: #211452;
}