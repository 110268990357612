@import "styles/constants.scss";

.Title {
  display: flex;
  justify-content: center;
  font-size: $xxxlarge-font-size;
  color: $primary;
  overflow: hidden;
  width: 100%;
  margin: 0;
  margin-bottom: 50px;
  font-weight: 700;
  line-height: calc(#{$xxxlarge-font-size} + 10px);
}

.Title.Small {
  font-size: $xlarge-font-size;
  line-height: calc(#{$xlarge-font-size} + 10px);
  margin: auto;
}

.Title.Medium {
  font-size: $xxlarge-font-size;
  line-height: calc(#{$xxlarge-font-size} + 14px);
  margin-bottom: 30px;
}

.Information {
  display: flex;
  justify-content: center;
  color: $primary;
  font-size: $large-font-size;
  margin: 0;
  margin-bottom: 20px;
}

.Subtitle {
  display: flex;
  justify-content: center;
  display: flex;
  color: $primary;
  margin-bottom: 15px;
  font-size: $large-font-size;
}
.InformationShape {
  color: $white;
  width: fit-content;
  background: $primary;
  border-radius: 40px;
  padding: 10px 40px;
}

.Important {
  color: $red;
  font-size: $large-font-size;
  font-weight: 700;
}

.ImportantShape {
  color: $red;
  width: fit-content;
  border-radius: 50px;
  font-size: $xxxlarge-font-size;
  font-weight: 700;
  padding: 10px 50px;
  margin: 0;
}
.Large{
  font-size: $xlarge-font-size;
}

.Error {
  color: $error;
}

.Warning {
  color: $warning;
}

.Success {
  color: $success;
}

.Information,
.Subtitle,
.TitleText {
  justify-content: center;
  div {
    text-align: center;
  }
}

.TitleText {
  height: auto!important;
}