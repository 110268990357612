@import "antd/dist/antd.css";
@import "styles/animation.scss";
@import "styles/styles.scss";

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 200 800;
  // /fonts/Raleway/Raleway-VariableFont_wght.ttf
  src: url("/assets/fonts/Raleway/Raleway-VariableFont_wght.ttf")
    format("truetype");
}

@font-face {
  font-family: "Now";
  src: url("/assets/fonts/Now/Now-Light.otf") format("truetype");
  font-weight: 100 200;
}
@font-face {
  font-family: "Now";
  src: url("/assets/fonts/Now/Now-Regular.otf") format("truetype");
  font-weight: 300 500;
}
@font-face {
  font-family: "Now";
  src: url("/assets/fonts/Now/Now-Medium.otf") format("truetype");
  font-weight: 600 900;
}

.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-center {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.ant-notification-notice-description {
  max-width: 82% !important;
}

.ant-notification-notice-content {
  padding: 10px !important;
}

body {
  margin: 0;
  font-family: "Now";
  color:$primary;
  font-weight: thin;
  font-size: $medium-font-size;
}

/*
 * Antd overwrites
 */
 .ant-table table { font-size: 28px; }

//BUTTONS
.ant-btn {
  font-size: $xlarge-font-size;
  font-weight: medium;
  height: $button-height;
  // do not put important
  border: 2px solid;
  border-color: $primary;
  color: $primary;
  box-shadow: none !important;
  border-radius: 5px;
}
.ant-btn-link {
  border-color: transparent;
}
.ant-btn-primary {
  background: $accent;
  color: $white;
  border-color: $accent;
}

.primary-color {
  color: $primary;
}

.ant-btn-sm {
  font-size: $large-font-size !important;
  height: $button-height !important;
}

.ant-modal-body {
  padding: 0;
}

.ant-btn:active,
.ant-btn:hover,
.ant-btn:focus {
  background: inherit;
  color: inherit;
  border-color: inherit !important;
}

// INPUTS
input,
.ant-input,
.ant-select,
.ant-select-selector,
.ant-radio-button-wrapper,
.ant-input-affix-wrapper {
  height: $input-height;
  border-color: $grey;
  font-size: $xlarge-font-size;
  line-height: $input-height;
  // new
  background: transparent;
  border: none;
  border-bottom: 2px solid $primary;
  border-color: $primary !important;
  box-shadow: none;
  padding-left: 20px;
  color: $accent;
  [type="search"]  {
    border-bottom: none;
  }
}
input:read-only {
  pointer-events: none;
}
.ant-select-selector {
  height: calc(#{$input-height} - 2px) !important;
  border: none !important;
  border-color: $primary !important;
}
.ant-select {
  padding-left: 0;
}
.ant-select-item-option {
  font-size: $xlarge-font-size;
  line-height: $xxlarge-font-size;
  color: $primary;
  padding: 20px 0;
  text-align: center;
}
.ant-btn-primary.disable-like-able {
  text-transform: uppercase;
}
.ant-btn-primary.disable-like-able[disabled],
.disable-like-able[disabled]:active {
  background: $accent;
  color: white;
  border-color: $accent;
  text-transform: uppercase;
}
.ant-input:focus,
.ant-input-focused {
  box-shadow: none !important;
}
.ant-input-affix-wrapper > input.ant-input:focus {
  box-shadow: none !important;
}
.ant-select-selection-item {
  line-height: $input-height !important;
}
// DANGER BTNS
.ant-btn-dangerous {
  border-color: $error;
  color: $error;
  background: white;
  &.ant-btn-primary {
    border-color: $error;
    background: $error;
    color:white;
  }
  &.ant-btn-link {
    color: $error;
  }
  .ant-input-group-addon {
    background-color: $white;
    border: none;
  }
}
.ant-input-affix-wrapper {
  padding: 0;

  padding-left: 10px;
  padding-right: 15px;
  overflow: hidden;
}
.ant-radio-button-wrapper {
  border: none !important;
  background-color: $sand !important;
  color: $primary !important;
  font-weight: 100;
}
.ant-radio-button-wrapper-checked {
  color: $white !important;
  background: $primary !important;
}
.ant-radio-button-wrapper-checked::before {
  background-color: $primary !important;
}

// LABEL
.ant-form-item-label > label {
  font-size: $medium-font-size !important;
}
.ant-checkbox-wrapper {
  font-size: $large-font-size;

  .ant-checkbox {
    transform: scale(2) translateY(-1px);
    margin-right: 10px;
  }
}
//Modal
.ant-modal {
  color:$primary;
}
.ant-modal-mask {
  background: rgba(33, 20, 82, 0.8); // $primary +80% opacity;
}

.ant-modal-content {
  border-radius: 30px !important;
  font-size: $large-font-size;
  width: auto;
  margin: auto;
  max-width: 95vw;
  padding: 25px;
}
.ant-modal-header {
  border-radius: 50px 50px 0 0;
  padding-top: 20px;
  border-bottom: none;
}
.ant-modal-footer {
  padding-bottom: 20px;
  border-top: none;
}
//STEPPER
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: $primary;
}
.ant-steps-icon-dot {
  background-color: $primary !important;
}
.ant-steps-item-container {
  width: 50px;
}
// TABS
.ant-tabs-ink-bar {
  background: $accent !important;
}
//ERRORS
.ant-form-item-explain {
  font-size: $large-font-size;
  line-height: 2;
}

.hg-candidate-box {
  position: fixed;
  z-index: 1;
  transform: translateY(-15px);
  max-width: none;
}
li.hg-candidate-box-list-item {
  width: 100px;
  height: 80px;
  font-size: 40px;
  font-weight: 700;
  background: #f4f4f4;
  color: $primary;
  border-radius: 10px;
  border: 1px solid $primary;
}
ul.hg-candidate-box-list {
  display: flex;
  gap: 7px;
}

// Carousel dots 
.dots-carousel {
  bottom: -70px!important;
  gap: 20px;
  li {

    height: 30px!important;
    width: 30px!important;
    background-color: #D9D9D9;
    border-radius: 100%;
      button {
        height: 100%!important;
        background-color: transparent!important;
      }
    &.slick-active{
      background-color: $primary;
    }
  }
}

// apply antd disabled design on phone input
.PhoneInput--disabled {
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}

.PhoneInput--disabled input {
  color: rgb(122, 122, 122);
}

// apply antd disabled design on password input
.ant-input-affix-wrapper-disabled {
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
