@import "styles/constants.scss";

.Buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 70px;
}
.Center {
  justify-content: space-evenly;
}

.Card {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1500px;
  margin: auto;
  background-color: $white;
}

.Illustration {
  height: 300px;
  margin-bottom: 50px;
}
.LargeImg {
  height: 500px;
}
.SmallImg {
  height: 100px;
}

.MediumImg {
  height: 300px;
}
