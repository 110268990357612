@import "styles/constants.scss";
$red: #9b4f86;
$xlarge-font-size: 58px;
$videowidth : 500px;

:root {
  --positionView: 1;
}

.previewModal {
  display: block; 
  position: fixed; 
  z-index: 1000000000000; 
  padding-top: 100px; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100vh;
  overflow: auto; 
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0,0.9);
}

.modalContent {
  margin: auto;
  display: block;
  width: 70%;
  max-width: 560px;
}

#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation */
.modalContent, #caption {  
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {-webkit-transform:scale(0)} 
  to {-webkit-transform:scale(1)}
}

@keyframes zoom {
  from {transform:scale(0)} 
  to {transform:scale(1)}
}

/* The Close Button */
.previewClose {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.previewClose:hover,
.previewClose:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

.UploadLoader{
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid $red;
  width: 60px;
  height: 60px;
  // position : absolute;
  // right : 14rem;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.ScreenShotOnGoing{
  color : $red;
  font-size :40px;
  font-weight: 600;
}

.Timer {
    color : $red;
    display: flex;
    font-size :$xlarge-font-size;
    font-weight: 600;
    flex-direction: row;
    justify-self: flex-start;
    align-items: center;
    width: 60px;
    gap: 10px;
    align-self: center;
  }

.shooterPage{
  display : flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
}
.container{
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap : 4rem
  }

.sideImage{
    width: 800px;
    display: flex;
    flex-direction: column;
    gap: 50px;
  }

  .sideImage img{
    width: 90%;
  }

  #ShooterContainer{
      width: 480px;
      margin-right : 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      overflow: hidden;  
  }
  
  #calc {
      height: 100%;
  }
  
  #videoElement {
      width : 100%;
  }
  
  #canvas{
      display: none;
      width: 100%;
      height: 100%;
  }
  
  #frame {
      height: 100%;
      position: absolute;
      z-index: 10;
  }

  .GetReadyPageContainer {
    width: 70%;
    display: grid;
    grid-template-columns: 0.3fr 0.4fr 0.3fr;
    gap: 5rem;
    margin: auto;
  }

  .GetReadyPageContainer img{
    width: 100%;
  }

  .getReadyImgMiddle {
    width: 100%;
  }

  .getReadyImgSide {
    width: 100%;
    align-self: center;
    justify-self: center;
  }

.IamReady{
    width: 55%;
    margin-left: auto;
    margin-right: auto;
}

.IamReadyImage {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    width: 100%;
}

.PreviewPage{
  position: relative;
  // width: 22rem;
  width: 1400px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}

.PreviewPageImage{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%; 
}

.LoadingPageImg{
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-top: auto;
  width: 100%; 
}

.LoadingPage{
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
}

.LoadingPageDiv1 {
  width: 100%;
  height: 500px;
  margin-left: auto;
  margin-right: auto;
}

.LoadingPageDiv2 {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

.Title {
  display: flex;
  justify-content: center;
  font-size: $xxxlarge-font-size;
  color: $primary;
  overflow: hidden;
  width: 100%;
  margin: 0;
  margin-bottom: 50px;
  font-weight: 700;
  line-height: calc(#{$xxxlarge-font-size} + 10px);
}

.TitleCustom {
  text-align: center;
  font-size: $xxxlarge-font-size;
  color: $primary;
  overflow: hidden;
  width: 100%;
  margin: 0;
  margin-bottom: 50px;
  font-weight: 700;
  line-height: calc(#{$xxxlarge-font-size} + 10px);
}

.Title.Small {
  font-size: $xlarge-font-size;
  line-height: calc(#{$xlarge-font-size} + 10px);
  margin: auto;
}

.Title.Medium {
  font-size: $xxlarge-font-size;
  line-height: calc(#{$xxlarge-font-size} + 14px);
  margin-bottom: 30px;
}

.Subtitle {
  display: flex;
  justify-content: center;
  display: flex;
  color: $primary;
  margin-bottom: 15px;
  font-size: $large-font-size;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 55%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: $primary;
  font-weight: bold;
  font-size: 20px;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
  font-size: $large-font-size;
}

/* Position the "next button" to the right */
.next {
  right: 40px;
  border-radius: 3px 0 0 3px;
}

.prev {
  left: 40px;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.numbertext{
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

.mySlidesInactive{
  display: none;
}

.PreviewRow{
display: flex;
width: 22rem;
justify-content: space-between;
}

.PreviewColumn{
  width: 5rem;
}

.rowImage{
  width:100%;
  border-radius: 50%;
}


.multiSlide {
    max-width: 1400px;
    height: 800px;
    display: flex;
    margin: auto;
    perspective: 600px;
    align-items: center;
    justify-content:space-evenly;
    border: 1px solid black;

    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}

.multiSlide div{
  scroll-snap-align:start;
  width: 420px;
  border-radius: 4px;
  -webkit-transition: all 500ms cubic-bezier(0.7,-0.9,0.3,1.4);
  -moz-transition: all 500ms cubic-bezier(0.7,-0.9,0.3,1.4);
  transition: all 500ms cubic-bezier(0.7,-0.9,0.3,1.4);
  opacity: 1; 
  border: 1px solid black;

}

.multiSlide div:nth-child(1) {
  transform: rotateY(-30deg);
  transform-origin: right center;
  opacity: 0.6;
}

.multiSlide div:nth-child(3) {
  transform: rotateY(30deg);
  transform-origin: left center;
  opacity: 0.6;
}

.PreviewmultiSlideImage{
  width: 100%;
  -webkit-transition: all 500ms cubic-bezier(0.7,-0.9,0.3,1.4);
  -moz-transition: all 500ms cubic-bezier(0.7,-0.9,0.3,1.4);
  transition: all 500ms cubic-bezier(0.7,-0.9,0.3,1.4);
}

.PreviewCardContent{
 display: flex;
 justify-content: space-between;
 align-items: center;
}

.carousel {
  width: 50vw;
  height: 530px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transform-style: preserve-3d;
  perspective: 300px;
  --items: 3;
  --middle: 2;
  --position: 1;
  pointer-events: auto;


}

.item {
  position: absolute;
  width: 300px;
  height: 450px;

  --r: calc(var(--positionView) - var(--offset));
  --abs: max(calc(var(--r) * -1), var(--r));
  transition: all 0.25s linear;
  transform: rotateY(calc(-10deg * var(--r)))
    translateX(calc(-300px * var(--r)));
  z-index: calc((var(--positionView) - var(--abs)));
}

.ImageSelected .PreviewPageImage{
  border : 4px solid #1d71b8;
  border-radius: 10px;
  padding: 7px;
  background: rgba(29, 113, 184, 0.15)
}

.item:nth-of-type(1) {
  --offset: 1;
  margin-right: 50px;
}
.item:nth-of-type(2) {
  --offset: 2;
}
.item:nth-of-type(3) {
  margin-left: 50px;
  --offset: 3;
}


.UploadFatButton {
    align-items: center;
    background: #FFFFFF;
    border-radius: 20px;
    box-shadow: 0px 2px 9px -1px rgba(0, 0, 0, 0.17);
    color:  #211452;;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 44px;
    font-weight: medium;
    justify-content: center;
    padding: 20px;
    position: relative;
    width: 420px;
    height: 390px;
    margin: 30px auto 70px auto;
}

.UploadFatButton span {
  color:  #211452 !important;
  font-size: $large-font-size;

}
.UploadFatButton__picto{
  border-radius: 17px;
  width: 260.4px;
  height: 260.4px;
  margin-bottom: 30px;
}

.UploadFatButton__picto img{
  width: 100%;
  border-radius: 17px;
  object-fit: cover;
}

.webOrMobileButton{
  font-size: 18px;
  color : #1D71B8;
  font-weight: 600;
  display: flex; 
  justify-content: center; 
  align-items : center; 
  gap : 10px; 
  cursor : pointer;
  padding: 15px ;
  text-decoration: underline;
}

.title__block{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title__icon{
width : 6rem;
margin-bottom: 2rem;
}

.title__icon img{
  width : 100%;
}

.title__text{
font-weight: bold;
font-size: 65px;
text-align: center;
color: #211452;
}