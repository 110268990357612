@import "styles/constants.scss";

.Layout {
  height: 100vh;
  width: 100%;
  background: $white !important;
}

.Header {
  background-color: transparent !important;
  height: $header-height !important;
}

.Content {
  margin: 0 50px;
  flex: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - #{$header-height} - #{$footer-height}) !important;
}

.Footer {
  background-color: transparent !important;
  height: $footer-height !important;
}

.Card {
  width: 100%;
  border: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
}