.support {
    display: flex;
    margin-top: 10px;
    margin-right: 0;
    width: 52px;
    height: 52px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    cursor: pointer;
}

.title__block{
    display: flex;
    margin-top: 10px;
    width: 1059px;
    height: 100px;
    padding-top: 6.69px;
    justify-content: center;
    align-items: center;
}
.title__text {
    color: #211452;
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 50px;
}