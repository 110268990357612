@import "styles/constants.scss";

.PaymentInformationText {
  font-size: $large-font-size;
  color: $primary;
}

.PaymentInformation {
  background-color: $sand;
  width: max-content;
  padding: 20px 50px;
  margin: auto;
  width: 900px;
}
