@import "styles/constants.scss";

.RadioButton {
  display: block;
  margin-top: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.Scroll {
  display: flex;
  flex-wrap: wrap;
  gap: 0 15px;
  max-height: 30vh;
  overflow-y: scroll;
}
.PatientInformation{
    font-size: $medium-font-size;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:10px;
    img {
      width: 80px;
    }
}
.Other{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  img {
    width: 80px;
  }
}
.patientBirthdate {

}
.SelectedBadge {
  position: absolute;
  top: 5px;
  left: 5px;
  font-size: $default-font-size;
  font-weight: 800;
  color: $primary;
  background: #d5ebef;
  border-radius: 17px;
  padding: 1px  7px;
  transition: all .2s;
  &.Checked {
    top: 5px;
    left: 19px;
    font-size: $default-font-size;
    font-weight: 800;
    color: $white;
    background: $accent;
    border-radius: 0 17px 17px 0;
    padding : 1px 7px 1px 10px
  }
}