@import "styles/constants.scss";

#Img  {
  height: 400px;
}

#CallBar {
  align-items: center;
  padding-bottom:20px;
}

.Col {
  text-align: center;
}
.ColLeft {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ColRight {
  text-align: right;
}
.DeviceInfo {
  padding: 13px;
  width: 100%;
  background: #30abc1;
  border-radius: 20px 20px 0 0;
  text-align: center;
  font-size: 28px;
  color: $white;
}
.DeviceWrapper {
  &.Closed {
    width : 0;
  }
  &.Opened {
    width: fit-content;
  }
  transition: all .3s ease-in;
}

#CallContainer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: $header-height;
  display: flex;
}
.ChildrenContainer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.LoadingBackgroundVideo {
  background-image: url("/assets/loaders/gimmick-video.gif");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100px;
  background-color: $white;
  overflow: hidden;
  transition: all 0.3s;
}

#LocalMedia {
  position: absolute;
  bottom: 50px;
  right: 20px;
  width: 300px;
  video {
    margin-bottom: -10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

#ToolMedia {

  // transition: all 0.3s;
  position: absolute;
  top:0;
  left: 0;
  right: 0;
  bottom:0;
  video {
    margin-bottom: -10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.RemoteContainer {
  position:relative;
  width: 100%;
  height: 100%;
}


#RemoteMedia {
  position: absolute;
  top:0;
  left: 0;
  right: 0;
  bottom:0;
  display: flex;
  justify-content: center;
  background-color: #FAFAFA;
  video {
    height: 100%;
  }
  &.VideoDeviceIsActive{
    z-index: 1;
    width: 300px;
    height: fit-content;
    top: 0;
    left: inherit;
    right: 20px;
    video {
      margin-bottom: -10px;
      height: 300px;
    }
  }
}

.DeviceImage {
  margin: 50px auto;
  width: 270px;
  display: block;
}

.DoctorInformation,
.HelpSupport {
  position: absolute;
  bottom: 50px;
  color: $primary;
  font-size: $medium-font-size;
  background: rgba(255, 255, 255, 0.75);
  padding: 15px;
}
.DoctorInformation {
  border:1px solid $primary;
  left: 20px;
  min-width: 420px;
}
.HelpSupport {
  width: 300px;
  right: 20px;
}
.InformationDoctorWaiting{
  font-size:$xlarge-font-size;
  text-align: center;
  font-weight: 500;
  margin:  20px 0 0 0;
}
.hangupButton {
  background-color: $error;
  color: white;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 25px;
  }
}

.HeaderDevice {
  font-size :$xlarge-font-size;
  color: $primary;
  display: flex;
  align-items: center;
}
.DeviceContainer {
  width: 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  transition: all .3s ease-in;
  gap: 20px;
}

.TitleTuto {
  font-size :$xlarge-font-size;
  color: $primary;
  line-height: $xxlarge-font-size;
  padding : 0 50px;
}
.ImageTuto {
  width: 400px;
}
.Timer {
  color : $red;
  font-size :$xlarge-font-size;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap:10px;
}
.Active {
  width: 950px;
  padding: 50px 0;
}
.WarningDevice{
  color: #F47C57;
  font-size: $large-font-size;
  display: flex;
  margin: 0 40px;
  align-items: center;
  line-height: $large-font-size;
  gap: 20px;
}

.HangupButton {
  color: $yellow !important;
  font-size: $xxlarge-font-size !important;
  font-weight: bold;
}