@import "styles/constants.scss";

.ProfileLink {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 15px;
  height: 50px;
  color: $primary;
  padding: 0 15px;
  border-radius: 12px;
  font-size: 25px;
  border: 1px solid transparent;
  box-shadow: 0 2px 9px -1px rgb(0 0 0 / 20%);
}

.Nowrap {
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.LanguageSelectorFrame {
  margin-top: 5px;
  margin-right: 0;
  display: flex;
  width: 98px;
  height: 54px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 4px;
  background:  #FFF;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.17);
  cursor: pointer;
}

.title__block{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title__text{
  color: #211452;
  text-align: center;
  font-family: Now;
  font-size: 65px;
  font-style: normal;
  font-weight: 600;
  line-height: 80px;
}